// import React from "react"
// import BlogLayout from "gatsby-theme-blog/src/components/layout"
//
// export default props => <BlogLayout {...props}>{props.children}</BlogLayout>

import React from "react"
// import { Global } from "@emotion/core"
import { css, Styled } from "theme-ui"
import { Layout, Main, Container } from "theme-ui"
import Footer from "./footer"
import Header from "./header"

export default props => (
  <>
    <Styled.root>
      <Header {...props} />
        <Container {...props}
          css={css({
            maxWidth: `container`,
            mx: `auto`,
            px: 3,
            paddingBottom: 3,
          })}
        >
          {props.children}
          
        </Container>
      <Footer/>
    </Styled.root>
  </>
)